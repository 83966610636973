<template>
    <div>
        <vue-breadcrumbs :breadCrumbs='breadCrumbs'/>
        <div class="vue_card">
            <booking-form @submitHandler='submitHandler' :limit='limit.length'/>
            <div v-if="ready" class="vue_card__info">
                <div class="locations">
                    <p>{{cardDetails.from_location.name}} - {{cardDetails.to_location.name+'('+ cardDetails.hotel_name + ')'}}</p>
                </div>
                <passengers v-for="item, index in passengers" :key="index" :item='item' :index='index'/>
                <div class="from_to">
                    <div class="from_to__box">
                        <p class="from_to__box__title">Отправление</p>
                        <div class="from_to__box__date">
                            <v-icon color="primary">mdi-calendar</v-icon>
                            <div>
                                <p>{{cardDetails.from_location.name}}</p>
                                <p>{{ $moment(cardDetails.check_out_time*1000).format('DD - MMM, YYYY') }}</p>
                                <p>{{ $moment(cardDetails.check_out_time*1000).format('HH:mm') }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="from_to__box">
                        <p class="from_to__box__title">Прибытие</p>
                        <div class="from_to__box__date">
                            <v-icon color="primary">mdi-calendar</v-icon>
                            <div>
                                <p>{{cardDetails.hotel_name}}</p>
                                <p>{{ $moment(cardDetails.arrival_time*1000).format('DD - MMM, YYYY') }}</p>
                                <p>{{ $moment(cardDetails.arrival_time*1000).format('HH:mm') }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <booking-card-people :people='cardDetails' class="my-3"/>
                <div class="total_sum">
                    <p>Итого</p>
                    <p>{{cardDetails.price}} KZT</p>
                </div>
            </div>
        </div>
        <vue-booking-modal :dialog='dialog' :exept="'Экскурсии'" @closeDialog='closeDialog'/>
    </div>
</template>

<script>
import VueBreadcrumbs from '@/components/ui/vueBreadcrumbs.vue'
import VueAvatar from '@/components/ui/vueAvatar.vue'
import BookingCardPeople from '@/components/booking/bookingCardPeople.vue'
import BookingForm from '@/components/booking/bookingForm.vue'
import VueBookingModal from '@/components/ui/vueBookingModal.vue'
import { busesService } from '@/services/buses.service.js'
import { setModel } from '@/utils/formatMask.js'
import Passengers from '@/components/buses/passengers.vue'
export default {
    components: {
        VueBreadcrumbs,
        VueAvatar,
        BookingCardPeople,
        BookingForm,
        VueBookingModal,
        Passengers
    },
    data: () => ({
        ready: false,
        dialog: false,
        cardDetails: {},
        peopleCount: [],
        passengers: [],
        hotel: {}
    }),
    computed: {
        breadCrumbs() {
            let seatQuery = {
                adult: this.$route.query.adult, 
                child: this.$route.query.child, 
                pensioner: this.$route.query.pensioner, 
                from_date: this.$route.query.from_date, 
                from_location: this.$route.query.from_location,
                to_location: this.$route.query.to_location,
            }
            return [
                {
                    text: 'Бронирование автобусов',
                    to: '/booking/buses/',
                    query: { ...seatQuery, hotel: this.$route.query.hotel }
                },
                {
                    text: 'Выбор места',
                    to: `/booking/buses/ow/${this.$route.params.id}/`,
                    query: { ...seatQuery, hotel: this.$route.query.hotel }
                },
                {
                    text: 'Бронирование',
                    to: `/booking/buses/ow/${this.$route.params.id}/card/`,
                }
            ]
        },
        limit() {
            const { adult, child, pensioner } = this.$route.query
            const arr = []
            for(let i = 0; i < adult; i++) {
                arr.push('adult')
            }
            for(let i = 0; i < child; i++) {
                arr.push('child')
            }
            for(let i = 0; i < pensioner; i++) {
                arr.push('pensioner')
            }
            return arr
        },
    },
    created() {
        this.getDetails()
    },
    methods: {
        setHotel() {
            // this.hotel = this.cardDetails.bus_hotels.find(el => el.hotel.id == this.$route.query.hotel)
        },
        setPassengers() {
            let seats =  this.$route.query.seats.split(',')
            for (let i = 0; i < seats.length; i++) {
                this.passengers.push({
                    seat: this.cardDetails.bus.bus_seat_places.find(el => el.id == seats[i]).name,
                    type: this.cardDetails.bus.bus_type,
                    number: this.cardDetails.bus.number
                })
            }
        },
        async getDetails() {
            try {
                this.$loading(true)
                const { adult, child, pensioner, hotel, seats } = this.$route.query
                this.cardDetails = await busesService.getBusCard({id: this.$route.params.id, adult, child, pensioner, hotel, seat_places: seats})
                console.log(this.cardDetails);
                this.setPassengers()
                this.setHotel()
                this.ready = true
            } catch(e) {
                console.error(e);
            } finally {
                this.$loading(false)
            }
        },
        closeDialog() {
            this.dialog = false
            this.$router.push('/booking/residence')
        },
        async submitHandler(data) {
            const formData = this.$copy(data)
            formData.user.forEach(element => {
                // setModel(element, 'phone')
                element.phone_number = element.phone_number.replace(/[() -]/g,'')
            });
            const bus_order_clients = []
            formData.user.forEach(el => {
                bus_order_clients.push({...el}) // birth_date: this.$moment(el.birth_date).valueOf()/1000
            })
            const diff = this.limit.length - formData.user.length
            for (let j = 0; j < diff; j++) {
                bus_order_clients.push(this.$copy(bus_order_clients[0]))
            }
            bus_order_clients.forEach((user, index) => {
                user.seat_place = Number(this.$route.query.seats.split(',')[index])
            })
            this.limit.forEach((tariff, index) => {
                bus_order_clients[index].tariff = tariff
            })
            await busesService.postBusOrders({
                order: [
                    {
                        seal_channel: formData.seal_channel,
                        route: this.$route.params.id,
                        is_rt: false,
                        hotel: this.$route.query.hotel,
                        bus_order_clients,
                    }
                ]
            })
            this.dialog = true
        },
    }
}
</script>

<style lang="scss" scoped>
.vue_card {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: start;
    grid-column-gap: 20px;
    &__info {
        background: #FFFFFF;
        box-shadow: 0px 10px 20px rgba(146, 178, 193, 0.3);
        border-radius: 5px;
        padding: 20px;
        .locations {
            text-align: center;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #021011;
            p {
                margin-bottom: 0;
            }
        }
    }
}
.from_to {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 30px;
    &__box {
        p {
            margin-bottom: 0;
        }
        &__title {
            font-size: 12px;
            line-height: 14px;
            color: #FF8413;
            margin-left: 34px;
        }
        &__date {
            margin-top: 5px;
            display: grid;
            grid-template-columns: 24px 1fr;
            grid-column-gap: 10px;
            align-items: start;
            p {
                font-size: 16px;
                line-height: 19px;
                color: #021011;
                margin-bottom: 5px;
            }
        }
    }
}
.total_sum {
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
        margin-bottom: 0;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
    }
}
</style>